let apiUrl = process.env.VUE_APP_API_URL;


class FloorPlanApi {

    constructor() {
        this.token = JSON.parse(window.localStorage.getItem('token'));
    }

    getList(params) {
        var url = apiUrl + 'floorplans?folderId=' + params.folderId + '&limit=' + params.limit + '&page=' + params.page;

        return api.call('get', url)
          .then(({ data }) => {
            return data;
        });
    }

    getListByFavourites(params) {
        var url = apiUrl + 'floorplans?isFavorite=true' + '&limit=' + params.limit + '&page=' + params.page;

        return api.call('get', url)
          .then(({ data }) => {
            return data;
        });
    }

    get(id) {
        var url = apiUrl + 'floorplans/';
        return api.call('get', url + id)
            .then(({ data }) => {
                return data
            });
    }

    getAllByFolderId(id) {
        var url = apiUrl + 'floorplans?folderId=';

        return api.call('get', url + id)
            .then(({ data }) => {
                return data
            });
    }

    create(data) {
        var url = apiUrl + 'floorPlans';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });

    }
    update(data, floorPlanId) {
        var url = apiUrl + 'floorPlans/' + floorPlanId;
        return api.call('patch', url, data)
            .then(({ data }) => {
                return data
            });
    }

    updateFavorite(payload, floorPlanId) {
        var url = apiUrl + 'floorPlans/' + floorPlanId + '/isfavourite';
        return api.call('patch', url, payload)
            .then(({ data }) => {
                return data
            });
    }

    createScale(data) {
        var url = apiUrl + 'scales'
        console.warn('data create is', data)
        return api.call('post', url, data)
            .then(({data}) => {
                return data
            })
    }

    updateScale(data) {
      console.warn('data', data)
        var url = apiUrl + 'scales/' + data.scaleId;
        return api.call('patch', url, data.scale)
            .then(({ data }) => {
                return data
            });
    }

    delete(id) {
        var url = apiUrl + 'floorPlans/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

}

export default FloorPlanApi;
