<template>
  <CRow>
    <CCol>
      <!--UPLOAD-->
      <form
        enctype="multipart/form-data"
        novalidate
        v-if="isInitial || isSaving"
      >
        <!-- <h4>Upload images</h4> -->
        <div class="dropbox">
          <input
            type="file"
            :name="uploadFieldName"
            :disabled="isSaving"
            @change="filesChange($event.target.files)"
            accept="image/*"
            class="input-file"
          />
          <p v-if="isInitial">
            Drag your file(s) here to begin<br />
            or click to browse
          </p>
          <p v-if="isSaving">Uploading file. Please wait...</p>
        </div>
      </form>
      <!--SUCCESS-->
      <div v-if="isSuccess">
        <p>Successfully upload image.</p>
        <p>
          <a href="javascript:void(0)" @click="reset()"
            >Click to upload again</a
          >
        </p>
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <p>Added failed.</p>
        <p>
          <a href="javascript:void(0)" @click="reset()">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
// swap as you need
//import { upload } from './file-upload.fake.service'; // fake service
//import { uploadImage } from "../../lib/fileUploadApi"; // real service
import FileUploadApi from "../../lib/fileUploadApi"; // real service

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "WidgetsUploadImage",
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "image",
      fileUploadApi: new FileUploadApi(),
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      this.fileUploadApi.uploadDocument(formData).then((x) => {
        console.log(x)
        this.currentStatus = STATUS_SUCCESS;
        this.$emit("uploaded", {
          uploadedFiles: x,
        });
      })
      .catch((err) => {
        this.uploadError = err.data;
        this.currentStatus = STATUS_FAILED;
        console.log(err.data)
      });
    },
    filesChange(fileList) {
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      const minSize = 100 * 1024; // 100KB in bytes
      const minWidth = 600;
      const minHeight = 600;
      let formData = new FormData();

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.size > maxSize) {
          alert('File size should not exceed 5MB');
          file = [];
        } else {
          // Create a FileReader to read image dimensions
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = () => {
              const width = img.width;
              const height = img.height;

              if (width < minWidth || height < minHeight) {
                alert('Image resolution should be at least 600x600 pixels');
              } else {
                formData.append('documentFile', file);
                this.save(formData);
              }
            };
          };
        }
      }
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
