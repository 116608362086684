const BASE_URL = process.env.VUE_APP_API_URL;

class FileUploadApi {
    uploadDocument(formData) {
        console.log('uploadDocument', formData)
        const url = `${BASE_URL}documents`;
        
        return api.call('post', url, formData).then(x => x.data);
    }
}

export default FileUploadApi;